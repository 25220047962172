export const appHead = {"meta":[{"charset":"utf-8"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"name":"author","content":"taminify.com"},{"name":"robots","content":"follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"},{"name":"description","content":"پلتفرم تخصصی دراپ شیپینگ"},{"name":"keywords","content":""},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"msapplication-TileColor","content":"#2d89ef"},{"name":"theme-color","content":"#f5f5f8"}],"link":[{"rel":"canonical","href":"https://taminify.com"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favIcon/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favIcon/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favIcon/favicon-16x16.png"},{"rel":"manifest","href":"/favIcon/site.webmanifest"},{"rel":"mask-icon","href":"/favIcon/safari-pinned-tab.svg","color":"#ff5128"},{"rel":"shortcut icon","href":"/favIcon/favicon.ico"}],"style":[],"script":[{"type":"application/ld+json","innerHTML":"\n          {\n              \"@context\": \"https://schema.org/\",\n              \"@type\": \"WebSite\",\n              \"name\": \"تامینیفای\",\n              \"url\": \"https://taminify.com/\",\n              \"potentialAction\": {\n                  \"@type\": \"SearchAction\",\n                  \"target\": \"{search_term_string}\",\n                  \"query-input\": \"required name=search_term_string\"\n              }\n          }"}],"noscript":[],"htmlAttrs":{"lang":"fa-IR","dir":"rtl","translate":"no"},"__dangerouslyDisableSanitizers":["script","iframe","noscript"]}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false